import { getUserfromSS } from "../Config/SmallFunctions";

const initalState = {
  view: "Table",
  loggedInUserId: getUserfromSS()?.empId || 0,
  messages: [],
  celebrations:[],
  // info:{leaves:0,permissions:0,timesheet:0}
  info:{leavesPending:0,permissionsPending:0,timesheetPending:0}
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_LOGGEDINUSER": return { ...state, loggedInUserId: action?.payload };
    case "UPDATE_MESSAGES": return { ...state, messages: action?.payload };
    case "UPDATE_NOTIFICATION_INFO":return{...state,info:{...state.info,...action.payload}}
    case "CELEBRATIONS":return{...state,celebrations:action.payload}
    default: return state;
  }
};

export { reducer };