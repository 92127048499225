import { useEffect, useState } from "react";
let isSubmitted = false;
let newErrors = {};
let hasErrors = false;
export const UseFormValidations = (metadata) => {
  const [data, setData] = useState(metadata.initialvalues || {});
  const [errors, setErrors] = useState({});

  useEffect(() => { isSubmitted == true && validateForm() }, [data])

  const returningData = (value, type) => {
    // console.log(value,"type")
    if (value != undefined && value != null && value != "") {
      switch (type) {
        case "Bool": return value == "true" ? true : false
        case "Alphabet": const trimmedValue = value?.replace(/(^\s*)|(\s*$)/, "")?.replace(/[ ]{2,}/gi, " ")?.replace(/\n +/, "\n")?.replace(/[^a-zA-Z ]/g, "");
          if (trimmedValue) return trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
          else return ""; // Return empty string if value is empty after trimming
        case "AlphaNumaric": const trimmedValue1 = value?.replace(/(^\s*)|(\s*$)/, "")?.replace(/[ ]{2,}/gi, " ")?.replace(/\n +/, "\n")?.replace(/[^a-zA-Z0-9\s]+/g, "") // Updated regex to include all special characters
          if (trimmedValue1) return trimmedValue1.charAt(0).toUpperCase() + trimmedValue1.slice(1);
          else return "";
        case "AlphaNumaricPan": const trimmedValue2 = value?.replace(/(^\s*)|(\s*$)/g, "")?.replace(/[ ]{2,}/g, " ")?.replace(/\n +/g, "\n")?.replace(/[^a-zA-Z0-9\n ]/g, "")?.toUpperCase();
          if (trimmedValue2) return trimmedValue2;
          else return "";
        case "AlphanumaricWithSpecial": return value?.replace(/(^\s*)|(\s*$)/, "")?.replace(/[ ]{2,}/gi, " ")?.replace(/\n +/, "\n")?.replace(/^(.)/, (match) => match.toUpperCase());
        case "Number": return parseInt(value?.replace(/[^0-9\.]/g, "")) || 0;
        case "NumberString": return value?.replace(/[^0-9\.]|(?<=\..*)\./g, "");
        case "Phone":
          let res = value?.replace("+91", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '');
          let l = res?.length;
          if (l <= 5) return res?.replace(/(\d{1})/, "+91 $1", "")
          else if (l > 5 && l <= 10) return res?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "")
          break
        case "Aadhaar":
          let res1 = value?.replace(/[^0-9]/g, "");
          let length = res1?.length;
          if (length <= 4) return res1;
          else if (length > 4 && length <= 8) return res1?.replace(/(\d{4})(\d{1})/, "$1 $2");
          else if (length > 8 && length <= 12) return res1?.replace(/(\d{4})(\d{4})(\d{1})/, "$1 $2 $3");
          break;
        case "UniversePhone":
          let str = value.replace(/[^0-9]/g, "");
          if (str.length >= 10) {
            // return str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            return str;
          } else {
            return str;
          }
        case "Email": return value?.replace(/(^\s*)|(\s*$)/, "")?.replace(/[ ]{1,}/gi, "")?.replace(/\n +/, "\n");
        case "Hours": return formatHourField(value);
        case "Minutes": return formatMinuteField(value)
        case "Array": return Array.isArray(value) && value?.length > 0 ? value : []
        case "": return value.replace(/^\s+/, "")?.replace(/\s+/g, " ");
        default: return value.replace(/^\s+/, "")?.replace(/\s+/g, " ");
      }
    } else return "";
  };

  const formChange = (type) => (e) => {
    let d = returningData(e?.target?.value, type);
    setData(p => ({ ...p, [e.target.name]: d }));
    submittingData(e.target.name, d);
  };

  const formChangeIn = (name, value) => {
    let d = returningData(value, "");
    setData(p => ({ ...p, [name]: d }));
    submittingData(name, d);
  };

  const handleChange = (name, type) => (s) => {
    let d = returningData(s, type);
    setData({ ...data, [name]: d });
    submittingData(name, d);
  };

  const formChange1 = (type, name) => (value, country, e, formattedValue) => {
    const processedValue = returningData(value, type);
    if (name) setData({ ...data, [name]: processedValue })
    submittingData(e.target.name, processedValue);
  };

  const submittingData = (name, value) => {
    if (isSubmitted) {
      validateFormControl(name, value);
      if (errors && errors[name] && !newErrors[name]) {
        delete errors?.[name];
        setErrors({
          ...errors,
          // [name]: "",
        });
      } else {
        setErrors({
          ...errors,
          ...newErrors,
        });
      }
      newErrors = {};
    }
  };


  const formatHourField = (value) => {
    const intValue = parseInt(value, 10);

    if (isNaN(intValue) || value === "") {
      return "00";
    } else if (intValue < 10) {
      return "0" + intValue;
    } else if (intValue >= 10 && intValue <= 24) {
      return intValue.toString();
    } else {
      return "00";
    }
  }

  const formatMinuteField = (value) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || value === "") {
      return "00";
    } else if (intValue < 10) {
      return "0" + intValue;
    } else if (intValue >= 10 && intValue <= 59) {
      return intValue.toString();
    } else {
      return "00";
    }
  }

  const handleSelectMultiple = (name) => (selectedValues) => {
    setData({ ...data, [name]: selectedValues });
  };

  const validateInnerSchema = (parentKey, key, value) => {
    const schema = metadata.validationSchema;
    const validationFormControl = schema?.[parentKey]?.innerSchema?.[key];
    let innerErrors = {};
    if (validationFormControl?.required && !value) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.required?.message,
      };
    } else if (
      validationFormControl?.minlength &&
      value.length < validationFormControl?.minlength?.value
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.minlength?.message,
      };
    } else if (
      validationFormControl?.maxlength &&
      value.length > validationFormControl?.maxlength?.value
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.maxlength?.message,
      };
    } else if (
      validationFormControl?.pattern &&
      !validationFormControl.pattern?.value?.test(value)
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.pattern?.message,
      };
    }
    return innerErrors;
  };

  const setV = (v) => {
    setData(v);
  };
  const resetData = () => setData({})
  const addData = (v) => {
    setData({ ...data, ...v });
  };

  const validateFormControl = (key, value) => {
    const schema = metadata.validationSchema;
    const validationFormControl = schema?.[key];
    if (validationFormControl?.required && !value) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.required?.message,
      };
    } else if (
      validationFormControl?.minlength &&
      value?.length < validationFormControl?.minlength?.value
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.minlength?.message,
      };
    } else if (
      validationFormControl?.maxlength &&
      value.length > validationFormControl?.maxlength?.value
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.maxlength?.message,
      };
    } else if (
      validationFormControl?.pattern &&
      !validationFormControl.pattern?.value?.test(value)
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.pattern?.message,
      };
    } else if (
      validationFormControl?.isArray &&
      validationFormControl?.isArray?.value
    ) {
      if (data?.[key] && data?.[key]?.length > 0) {
        let res = data?.[key]?.map((e, i) => {
          let innerErrors = {};
          for (const innerkey in validationFormControl?.innerSchema) {
            let res = validateInnerSchema(key, innerkey, e?.[innerkey] || "");
            innerErrors = { ...innerErrors, ...res };
          }
          return innerErrors;
        });
        const allEmptyObjects = res.every((o) => Object.keys(o).length === 0);
        if (!allEmptyObjects) {
          newErrors = {
            ...newErrors,
            [key]: res,
          };
        }
      }
    }
  };

  const writeData = (index, name, key, type) => (e) => {
    // e.preventDefault();
    let d = returningData(e?.target?.value, type);
    // console.log(d, "d")
    if (name) {
      let fd = data[name];
      let present = fd[index];
      present[key] = d;
      fd[index] = present;
      // console.log(fd[index], "index")
      setData({ ...data, [name]: fd });
    }
    submittingData(name, "");
  };

  const writeAddObject = (index, name, obj) => {
    if (name) {
      let fd = data[name];
      let present = fd[index];
      present = { ...present, ...obj };
      fd[index] = present;
      setData({ ...data, [name]: fd });
      submittingData(name, "")
    }
  };
  const addObject = (u) => {
    setData(p => ({ ...p, ...u }))
    // validateForm()
  }

  const WriteMultiSelect = (index, name, key,) => (selectedValues) => {
    let fd = data[name];
    let present = fd[index];
    present[key] = selectedValues;
    fd[index] = present;
    setData({ ...data, [name]: fd });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isSubmitted = true;
    validateForm();
    if (!hasErrors) {
      metadata.submit();
    }
  };



  const handleDateChange = (e, name) => {
    let str;
    if (name) {
      if (e != null) {
        e?.setMinutes(0);
        e?.setHours(0);
        str = new Date(
          e?.setMinutes(
            e?.getTimezoneOffset() < 0
              ? -e?.getTimezoneOffset()
              : e?.getTimezoneOffset()
          )
        );
      } else {
        str = "";
      }
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);

    }
  };

  const validateForm = () => {
    for (const key in metadata.validationSchema) {
      validateFormControl(key, data?.[key]);
    }
    setErrors(newErrors);
    var size = Object.keys(newErrors).length;
    if (size) {
      hasErrors = true;
    } else {
      hasErrors = false;
    }
    newErrors = {};
  };

  const addItem = (key, item) => {
    if (data[key] && data[key]?.length > 0) {
      setData({ ...data, [key]: [...data?.[key], item || {}] });
    } else {
      setData({ ...data, [key]: [item || {}] });
    }
  };
  const addItemOnTop = (key, item) => {
    if (data[key] && data[key]?.length > 0) {
      setData({ ...data, [key]: [{}, ...data?.[key]] });
    } else {
      setData({ ...data, [key]: [{}] });
    }
  };

  const removeItem = (name, index) => {
    setData({ ...data, [name]: data[name]?.filter((v, i) => i != index) });
  };
  const writeMultiSelect = (index, name, key) => (selectedValues) => {
    let fd = data[name];
    let present = fd[index];
    present[key] = selectedValues;
    fd[index] = present;
    setData({ ...data, [name]: fd });
  };
  const handleCheckbox = (name) => (e) => {
    setData({
      ...data,
      [name]: e.target.checked ? e.target.value : "",
    });
  };
  const handleCheckbox1 = (fieldName) => (event) => {
    const { checked } = event.target;
    setData((prevData) => ({
      ...prevData,
      [fieldName]: checked,
    }));
  };
  return {
    data,
    errors,
    writeData,
    writeAddObject,
    WriteMultiSelect,
    formChange,
    formChangeIn,
    handleChange,
    handleSubmit,
    setV,
    resetData,
    addData,
    addItem,
    addItemOnTop,
    addObject,
    removeItem,
    handleDateChange,
    handleSelectMultiple,
    writeMultiSelect,
    handleCheckbox,
    handleCheckbox1,
    formChange1
  };
};
