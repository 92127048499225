export const genderList = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
export const projectStatus = [{ name: "Active", value: "1" }, { name: "Inactive", value: "2" }, { name: "Completed", value: "3", name: "Hold", value: "4" }]
export const role = [{ name: "Manager", value: "1" }, { name: "Developer", value: "2" }, { name: "QA", value: "3", name: "Project Lead", value: "4" }]
export const countryCodes = ['af', 'ax', 'al', 'dz', 'as', 'ad', 'ao', 'ai', 'aq', 'ag', 'ar', 'am', 'aw', 'au', 'at', 'az', 'bs', 'bh', 'bd', 'bb', 'by', 'be', 'bz', 'bj', 'bm', 'bt', 'bo', 'bq', 'ba', 'bw', 'bv', 'br', 'io', 'bn', 'bg', 'bf', 'bi', 'cv', 'kh', 'cm', 'ca', 'ky', 'cf', 'td', 'cl', 'cn', 'cx', 'cc', 'co', 'km', 'cg', 'cd', 'ck', 'cr', 'ci', 'hr', 'cu', 'cw', 'cy', 'cz', 'dk', 'dj', 'dm', 'do', 'ec', 'eg', 'sv', 'gq', 'er', 'ee', 'sz', 'et', 'fk', 'fo', 'fj', 'fi', 'fr', 'gf', 'pf', 'tf', 'ga', 'gm', 'ge', 'de', 'gh', 'gi', 'gr', 'gl', 'gd', 'gp', 'gu', 'gt', 'gg', 'gn', 'gw', 'gy', 'ht', 'hm', 'va', 'hn', 'hk', 'hu', 'is', 'in', 'id', 'ir', 'iq', 'ie', 'im', 'il', 'it', 'jm', 'jp', 'je', 'jo', 'kz', 'ke', 'ki', 'kp', 'kr', 'kw', 'kg', 'la', 'lv', 'lb', 'ls', 'lr', 'ly', 'li', 'lt', 'lu', 'mo', 'mg', 'mw', 'my', 'mv', 'ml', 'mt', 'mh', 'mq', 'mr', 'mu', 'yt', 'mx', 'fm', 'md', 'mc', 'mn', 'me', 'ms', 'ma', 'mz', 'mm', 'na', 'nr', 'np', 'nl', 'nc', 'nz', 'ni', 'ne', 'ng', 'nu', 'nf', 'mk', 'mp', 'no', 'om', 'pk', 'pw', 'ps', 'pa', 'pg', 'py', 'pe', 'ph', 'pn', 'pl', 'pt', 'pr', 'qa', 're', 'ro', 'ru', 'rw', 'bl', 'sh', 'kn', 'lc', 'mf', 'pm', 'vc', 'ws', 'sm', 'st', 'sa', 'sn', 'rs', 'sc', 'sl', 'sg', 'sx', 'sk', 'si', 'sb', 'so', 'za', 'gs', 'ss', 'es', 'lk', 'sd', 'sr', 'sj', 'se', 'ch', 'sy', 'tw', 'tj', 'tz', 'th', 'tl', 'tg', 'tk', 'to', 'tt', 'tn', 'tr', 'tm', 'tc', 'tv', 'ug', 'ua', 'ae', 'gb', 'us', 'um', 'uy', 'uz', 'vu', 've', 'vn', 'vg', 'vi', 'wf', 'eh', 'ye', 'zm', 'zw']
export const mobileCodes = ['1', '7', '20', '27', '30', '31', '32', '33', '34', '36', '39', '40', '41', '43', '44', '45', '46', '47', '48', '49', '51', '52', '53', '54', '55', '56', '57', '58', '60', '61', '62', '63', '64', '65', '66', '81', '82', '84', '86', '90', '91', '92', '93', '94', '95', '98', '211', '212', '213', '216', '218', '220', '221', '222', '223', '224', '225', '226', '227', '228', '229', '230', '231', '232', '233', '234', '235', '236', '237', '238', '239', '240', '241', '242', '243', '244', '245', '246', '248', '249', '250', '251', '252', '253', '254', '255', '256', '257', '258', '260', '261', '262', '263', '264', '265', '266', '267', '268', '269', '290', '291', '297', '298', '299', '350', '351', '352', '353', '354', '355', '356', '357', '358', '359', '370', '371', '372', '373', '374', '375', '376', '377', '378', '379', '380', '381', '382', '383', '385', '386', '387', '389', '420', '421', '423', '500', '501', '502', '503', '504', '505', '506', '507', '508', '509', '590', '591', '592', '593', '594', '595', '596', '597', '598', '599', '670', '672', '673', '674', '675', '676', '677', '678', '679', '680', '681', '682', '683', '685', '686', '687', '688', '689', '690', '691', '692', '800', '850', '852', '853', '855', '856', '870', '875', '876', '877', '878', '879', '880', '881', '882', '883', '886', '960', '961', '962', '963', '964', '965', '966', '967', '968', '970', '971', '972', '973', '974', '975', '976', '977', '979', '991', '992', '993', '994', '995', '996', '998']

export const monthList = [
  { name: "Janaury", value: "01" },
  { name: "February", value: "02" },
  { name: "March", value: "03" },
  { name: "April", value: "04" },
  { name: "May", value: "05" },
  { name: "June", value: "06" },
  { name: "July", value: "07" },
  { name: "August", value: "08" },
  { name: "September", value: "09" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
]

export const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday "]

export const menuItems = [

  {
    label: 'CC Forms',
    submenuItems: [
      { label: 'Change Control Request form' },
      { label: 'Change Control Record' },
    ],
  },

  {
    label: 'CH Forms',
    submenuItems: [
      { label: 'Customer Complaint Register' },
      { label: 'Customer Complaint Investigating Form' },
    ],
  },

  {
    label: 'CME07 Forms',
    submenuItems: [
      { label: 'Master List of Monitoring & Measuring Equipment' },
      { label: 'MME History Card' },
      { label: 'MME Calibration Calender' },
      { label: 'Malfunctioning of MME Register' },
    ],
  },
  {
    label: 'CNP Forms',
    submenuItems: [
      { label: 'Non Conforming Material Report' },
      { label: 'Non Conforming Material Register' },
      { label: 'Accept On Deviation Record' },
      { label: 'Deviation Register' },
      { label: 'Returned Product Disposal Register' },
      { label: 'Rejected RM PM Disposal Register' },
    ],
  },
  {
    label: 'COD Forms',
    submenuItems: [
      { label: 'Statutory Certificates Master list' },
      { label: 'Regulatory Certificates Master List' },
      { label: 'External Quality Documents Master List' },
      { label: 'Quality Procedures Master List' },
      { label: 'Control Copy Issue Register' },
      { label: 'Document Amendment Request Review Approval Record' },
      { label: 'Superceded Document List' },
      { label: 'Standards Issue Register' },
    ],
  },
  {
    label: 'COR04 Forms',
    submenuItems: [
      { label: 'Master List of Quality Records' },
      { label: 'Quality Records Disposal Record' },
      { label: 'Confidential Records List' },
    ],
  },

  {
    label: 'CRP Forms',
    submenuItems: [
      { label: 'Inquiry Register', },
      { label: 'Customer Requirement Review Record', },
      { label: 'Quotation' },
      { label: 'Order Review Form', },
      { label: 'Sales Order Form', },
      { label: 'Customer Master List', },
      { label: 'Customer Specification Change Control Record', },
    ],
  },
  {
    label: 'DAD Forms',
    submenuItems: [
      { label: 'Design And Development Planing Record' },
      { label: 'Design And Development Team Formation Record' },
      { label: 'Design Input Review Record' },
      { label: 'Commercial Review Record' },
      { label: 'Regulatory  Requirements Review Record' },
      { label: 'Design Feasibilty Review Record', },
      { label: 'Manufacturing Feasibilty Review Record', },
      { label: 'Manpower Planning Record' },
      { label: 'Bill of Material' },
      { label: 'Manufacturing Process Flow' },
      { label: 'Design Output Review Record' },
      { label: 'Design And Dvelopment Verification Record' },
      { label: 'Design And Dvelopment Valodation Record' },
      { label: 'Design Transfer Form' },
      { label: 'Design Change Control Request Form' },
      { label: 'Design Change Review and Approval Form' },
    ],
  },
  {
    label: 'FB Forms',

    submenuItems: [
      { label: 'Feedback Planner' },
      { label: 'Customer Feedback Form' },
    ],
  },

  {
    label: 'HR06 Forms',
    submenuItems: [
      { label: 'Process Competence Chart' },
      { label: 'Personnel Competence Chart' },
      { label: 'Design Input Review Record' },
      { label: 'List of Employees' },
      { label: 'Training Need Identification' },
      { label: 'Training Plan' },
      { label: 'Training Record' },
      { label: 'Personnel Protective Wear Record' },
      { label: 'Medical Checkup Planner and Record' },
      { label: 'Laundry Register' },
      { label: 'First Aid Log Book' },
      { label: 'Medical Checkup Record' },
    ],
  },

  {
    label: 'IA Forms',
    submenuItems: [
      { label: 'Annual Internal Audit Paln' },
      { label: 'Internal Audit Schedule' },
      { label: 'List of Internal Auditors' },
      { label: 'Internal Audit Observation' },
      { label: 'Annex to Audit Observation' },
      { label: 'Non Conformity Report' },
      { label: 'Internal Audit Summary Report' },

    ],
  },
  {
    label: 'IF06 Forms',
    submenuItems: [
      { label: 'Master List of Process Equipments' },
      { label: 'Equipment Commissioning Record' },
      { label: 'Equipment Preventive Maintenance Planner' },
      { label: 'Equipment Preventive Maintenance Record' },
      { label: 'Equipment Breakdown Record' },
      { label: 'Equipment Spares Register' },

    ],
  },
  {
    label: 'MDF Forms',
    submenuItems: [
      { label: 'Master List of Medicial Devices' },


    ],
  },
  {
    label: 'MOP Forms',
    submenuItems: [
      { label: 'Product Codes' },
      { label: 'FP Batch Allotment Register' },
      { label: 'Inprocess Quality Plan IPQP' },
      { label: 'IPQP FPQP Register' },
      { label: 'Finished Product Quality Plan' },
      { label: 'Inprocess Test Report' },
      { label: 'Batch Release Report' },
      { label: 'Control Samples Register' },


    ],
  },
  {
    label: 'MRM Forms',
    submenuItems: [
      { label: 'Management Review Meeting Committe Members' },
      { label: 'Management Review Meeting Planner' },
      { label: 'Management Review Meeting Notice' },
      { label: 'Management Review Meeting Attendance Sheet' },
      { label: 'Management Review Meeting Minutes' },

    ],
  },
  {
    label: 'AOD Forms',
    submenuItems: [
      { label: 'Analysis of Data' },
    ],
  },
  {
    label: 'CA Forms',
    submenuItems: [
      { label: 'Corrective Actions Register' },
    ],
  },
  {
    label: 'CI Forms',
    submenuItems: [
      { label: 'Continual Improvement Record' },
    ],
  },
  {
    label: 'PA Forms',
    submenuItems: [
      { label: 'Preventive Action Register' },
    ],
  },
  {
    label: 'QO Forms',
    submenuItems: [
      { label: 'Quality Objective Review Records' },
    ],
  },
  {
    label: 'RPR Forms',
    submenuItems: [
      { label: 'Risk Management Records' },
    ],
  },
  {
    label: 'QSP Forms',
    submenuItems: [
      { label: 'Procedure for Analysis of Data (AOD)' },
      { label: 'Procedure for Corrective Action (CA)' },
      { label: 'Procedure for Control of Changes (CC)' },
      { label: 'Procedure for Handling Customer Complaint (CH)' },
      { label: 'Procedure for Continual Improvement (CI)' },
      { label: 'Procedure for Calibration of Monitoring Measuring Equipment (CME)' },
      { label: 'Procedure for Control of Non Conforming Product (CNP)' },
      { label: 'Control of Documents (COD)' },
      { label: 'Control of Records (COR)' },
      { label: 'Procedure for Customer Property (CP)' },
      { label: 'Procedure for Control of Production and Service Provision (CPSP)' },
      { label: 'Procedure for Customer Related Process (CRP)' },
      { label: "Procedure for Design and Development (DAD)" },
      { label: "Procedure for Monitoring Feedback (FB)" },
      { label: "Procedure for Human Resource Process (HR)" },
      { label: "Procedure for Identification (I)" },
      { label: "Procedure for Internal Audit (IA)" },
      { label: "Procedure for Communication (IC)" },
      { label: "Procedure for Infrastructure (IF)" },
      { label: "Procedure for Medical device file (MDF)" },
      { label: "Procedure for Monitoring and Measurement of Product (MOP)" },
      { label: "Procedure for Monitoring and Measurement of Processes (MOPR)" },
      { label: "Procedure for Management Review (MR)" },
      { label: "Procedure for Preventive Action (PA)" },
      { label: "Procedure for Outsourcing (PFO)" },
      { label: "Procedure for Presrvation (POP)" },
      { label: "Procedure for Sterilization Validation (PSV)" },
      { label: "Procedure for Purchasing (PUR)" },
      { label: "Procedure for Risk Management for Product Realization (RPR)" },
      { label: "Procedure for Reporting to Regulatory Authority (RRA)" },
      { label: "Procedure for Sterilization of Medicial Device (SMD)" },
      { label: "Procedure for Traceability (T))" },
      { label: "Procedure for Validation of Production and Service Provision (VPSP)" },
      { label: "Procedure for Environment and Contamination Control (WE)" },
    ],
  },
  {
    label: 'CH',
    submenuItems: [
      { label: 'Procedure for Product Recall' },
    ],
  },
  {
    label: 'CNP Forms',
    submenuItems: [
      { label: 'Procedure for Rework' },
      { label: 'Vigilance Procedure' },

    ],
  },


];

export const hours = ["01", "02", "03", "04", '05', "06", "07", "08", "09", "10", "11", '12', "13", "14", '15', "16", "17", "18", "19", "20", "21", "22", "23", "24"];
export const minutes = ["00", "15", "30", "45"];
export const task = [{ name: "Development", value: 1 }, { name: "QA", value: 2 }, { name: "UI/UX", value: 3 }, { name: "Meetings/Calls", value: 4 }]